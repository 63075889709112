<template>
	<div>
		<div class="row m-0">
			<div class="col-12 text-center">
				<div class="form-group m-0">
					<label for=""><b>Seleccione si tiene una herramienta</b></label>
					<br>
					<div class="custom-control custom-switch custom-switch-off-warning custom-switch-on-success">
						<input type="checkbox" class="custom-control-input" id="customSwitch3" @change="checkinput">
						<label v-if="checkherramientas" class="custom-control-label" for="customSwitch3">SI
							TIENE</label>
						<label v-else class="custom-control-label" for="customSwitch3">NO TIENE</label>
					</div>
				</div>
			</div>
		</div>
		<div class="m-0">
			<div v-if="checkherramienta">
				<div class="col-12 text-center m-0">
					<a class="" href="#" @click.prevent="inputadd"><i class="bi bi-plus-circle"
							style="font-size: 30px; margin: 0;"></i></a>
				</div>
				<div v-if="typeof form.herramientas !== 'undefined' ">
					<!--Inputs Guardados -->
					<div class="row" v-for="(item,index) in form.herramientas" :key="item.id">
						<div class="col-4 text-center">
							<label for="" class="text-uppercase ">Herramienta de trabajo</label>
							<input type="text" v-model="item.nombre" class="form-control">
						</div>
						<div class="col-3 text-center">
							<label for="" class="text-uppercase ">Marca</label>
							<input type="text" v-model="item.marca" class="form-control">
						</div>
						<div class="col-3 text-center">
							<label for="" class="text-uppercase ">Serial</label>
							<input type="text" v-model="item.serial" class="form-control">
						</div>
						<div class="col-2 text-center">
							<label for="" class="text-uppercase ">Eliminar</label>
							<br>
							<a href="#" @click.prevent="deleteinputDB(item.id,index)"><i class="bi bi-trash-fill"
									style="font-size:31px;"></i></a>
						</div>
					</div>
				</div>
				<!--////-->
				<!--Inputs Nuevos -->
				<div class="row" v-for="(input, index) in inputs" :key="index">
					<div class="col-4 text-center">
						<label for="" class="text-uppercase ">Herramienta de trabajo</label>
						<input type="text" v-model="input.value_herramienta" class="form-control">
					</div>
					<div class="col-3 text-center">
						<label for="" class="text-uppercase ">Marca</label>
						<input type="text" v-model="input.value_marca" class="form-control">
					</div>
					<div class="col-3 text-center">
						<label for="" class="text-uppercase ">Serial</label>

						<input type="text" v-model="input.value_serial" class="form-control">
					</div>
					<div class="col-2 text-center">
						<label for="" class="text-uppercase ">Eliminar</label>
						<br>
						<a href="#" @click.prevent="deleteinputNew(index)"><i class="bi bi-trash-fill"
								style="font-size:31px;"></i></a>
					</div>
				</div>
				<!--////-->
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props: ['form', 'inputs'],
	data: function () {
		return {
			checkherramientas: false,
			valradio: 'no',
		}
	},
	mounted() {
		this.form.herramientastatus = 'no';
	},
	computed: {
		//Chequeamos que la herramienta venga con estatus de si 
		checkherramienta() {
			return this.form.herramientastatus == 'si' ? true : false
		}
	},
	methods: {
		//Cuando el radio cambie de valor validamos y lo enviamos a la vista 
		checkinput() {
			this.valradio = 'no';
			if (this.checkherramientas) {
				this.checkherramientas = false;
				this.valradio = 'no';
			} else {
				this.checkherramientas = true;
				this.valradio = 'si';
			}
			this.$emit('herramientastatus', this.valradio);
		},
		//*****************************//
		//Funciones para  nuevos inputs//
		//*****************************//
		inputadd() {
			this.inputs.push(
				{
					value_herramienta: '',
					value_marca: '',
					value_serial: ''
				}
			)
			this.$emit('inputarray', this.inputs)
		},
		deleteinputNew(index) {
			this.inputs.splice(index, 1);
		},
		///---------END----------------///

		//*****************************//
		//Funciones para inputs DB //
		//*****************************//
		deleteinputDB(id, index) {

			swal.fire({
				title: 'Estas seguro?',
				text: "Esta accción es irreversible",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Si, eliminar!'
			}).then((result) => {
				if (result.isConfirmed) {
					axios.post(route('herramientas.delete'), { id }).then((response) => {
						this.form.herramientas.splice(index, 1);
					}).catch((error) => {
						console.error(error);
					})
				}
			})
		}
		///---------END----------------///

	},
}
</script>