<template>
	<div>
		<section-content>
			<!-- Main row -->
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header bg bg-info">
							<div class="row">
								<div class="col-6">
									<b><i class="bi bi-eye-fill"></i> Agregar Empleado</b>
								</div>
								<div class="col-6 text-right">
									<a @click="$router.go(-1)" href="#">
										<b class="text-white"><i class="bi bi-reply-fill"></i> Atras..</b>
									</a>
								</div>
							</div>
						</div>
						<div class="card-body" id="bodycontent">
							<errors-form :errors="validationForm" />
							<success-message :message="message_success" />
							<div class="row">
								<div class="col-md-2"></div>
								<div class="col-md-8">
									<form @submit.prevent="saveform" id="formContainer"
										class="container border border-4 rounded-lg p-3 elevation-4">
										<!--Inputs selects y otros en un componente llamado head-form-->
										<head-form :form="form" type="create" @dni="form.dni = $event"
											@empleado="form.empleado = $event" @cargo="form.cargo = $event"
											@itemjson="form.itemsjson = $event" />

										<div class="row mt-3">
											<div class="col-12 text-center">
												<button class="btn btn-primary"><i class="bi bi-save2-fill"></i>
													Guardar</button>
											</div>
										</div>
									</form>
								</div>
								<div class="col-md-2"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /.row (main row) -->
		</section-content>

	</div>
</template>
<script>
import SectionContent from "@/components/SectionContent.vue"
import HeaderTitle from "@/components/HeaderTitle.vue"
import HeadForm from "@/components/empleados/head.vue"

import ErrorsForm from "@/components/ValidationErrors.vue"
import successMessage from '@/components/SuccessMessage.vue';
import route from "../../../../vendor/tightenco/ziggy/src/js"

export default {
	components: {
		SectionContent, HeaderTitle, HeadForm, successMessage, ErrorsForm
	},
	data: function () {
		return {
			form: {
				dni: '',
				empleado: '',
				cargo: '',
				itemsjson: {
					oficina: { id: 0, name: '-' },
					cargo: {id: 0, name: '-'},
				},
			},
			inputs: [],
			validationForm: [],
			message_success: '',
			fullPage: false
		}

	},

	methods: {
		loader(elementid) {
			return this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : document.getElementById(elementid),
				canCancel: false,
				onCancel: this.onCancel,
			});
		},
		resetmessage() {
			let me = this;
			setTimeout(function () { me.message_success = ''; me.validationForm = []; }, 2000);
		},
		clearform() {
			let me = this;
			Object.keys(this.form).forEach(function (key) {
				if (Array.isArray(me.form[key])) {
					me.form[key] = []
				} else {
					me.form[key] = null;
				}
			})
		},

		saveform() {
			let loader = this.loader('formContainer');
			let me = this;
			this.message_success = '';
			this.form.inputs = this.inputs;
			axios.post(route('empleados.create'), this.form).then(function (response) {
				me.validationForm = ''
				me.message_success = response.data.success;
				setTimeout(function () {
					me.message_success = response.data.success;
					history.back();
				}, 1000);
			})
				.catch(function (error) {
					loader.hide();
					me.validationForm = '';
					if (error.response.status == 422) {
						me.validationForm = error.response.data;
					} else if (error.response.status == 500) {
						alert(error.response.data.message)
					}
				});
		},
	},
	mounted() {

	}
}
</script>