<template>
	<div>
		<!-- Main row -->
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header bg bg-primary">
						<b><i class="bi bi-eye-fill"></i>&nbsp;&nbsp;Visitas programadas</b>
					</div>
					<div class="card-body">
						<div class="row g-3">
							<div class="col-lg-4">
								<div class="input-group">
									<router-link to="/visitas/create" class="btn btn-info">
										<i class="bi bi-plus-circle"></i>
										<b>Agregar Visita</b>
									</router-link>
									<span>&nbsp;&nbsp;</span>
									<select name="tipovisita" id="tipovisita" class="form-control jsoninput"
										data-name="tipovisita" data-width="auto" @change="jsonform">
										<option value="">Seleccionar tipo de Visita...</option>
										<option v-for="item in tipovisitas" :key="item.id" :data-name="item.nombre"
											:data-id="item.id" :selected="item.id == jsonselects.tipovisita.id">
											{{item.nombre}}
										</option>
									</select>
								</div>
							</div>

							<div class="col-lg-8">
								<DatePicker></DatePicker>
							</div>

						</div>
						<hr>
						<div class="table-responsive">
							<data-table :columns="columns" class="table" :urltable="url_table"></data-table>
						</div>
					</div>
				</div>

			</div>
		</div>
		<modal :showModal="showModal" :key="componentKey">
			<template v-slot:header>
				Imagen de la visita <b>{{ namemodal }}</b>
			</template>
			<img :src="`${srcmodal}`" alt="" class="img-fluid w-100">
		</modal>

		<!-- /.row (main row) -->
	</div>
</template>
<script>
import DataTable from '@/components/datatables/DataTableVisitas.vue';
import DatePicker from '@/components/DatePicker.vue';
import Modal from '@/components/Modal.vue';


export default {
	props: ['typevisita'],
	components: {
		DataTable,
		Modal,
		DatePicker
	},
	data: function () {
		return {
			url_table: route('visitas.index'),
			showModal: false,
			componentKey: 0,
			srcmodal: null,
			namemodal: null,
			errors: {},
			tipovisitas: null,
			form: {
				nombre: '',
				itemsjson: {
					tipovisita: { id: 0, name: '-' },
				},
			},
			columns: [
				{
					data: 'id',
					visible: false,
				},
				{
					data: 'fecha',
				},
				{
					data: 'fecha_programada',
					title: 'Fecha.P'
				},
				{
					data: 'tipovisita',
					render: function (data, type, row) {
						if (row.tipovisita) {
							var tvisita = `${row.tipovisita}`;
						} else {
							var tvisita = ``;
						}
						return tvisita;
					},
					title: 'Tipo&nbsp;Visita'
				},
				{
					data: 'nombre',
				},
				{
					data: 'dni',
					render: function (data, type, row) {
						if (row.tipo) {
							var ddni = `${row.tipo} ${row.dni}`;
						} else {
							var ddni = `${row.dni}`;
						}
						return ddni;
					},
					title: 'Identificacion ',

				},
				{
					data: 'entidad',
					title: 'Entidad'
				},
				{
					data: 'motivo',
					title: 'Motivo'
				},
				{
					data: 'empleado',
					title: 'Empleado Publico'
				},
				{
					data: 'sede',
					title: 'Sede'
				},
				{
					data: 'oficina',
					title: 'Oficina'
				},
				{
					data: 'hora_entrada',
					orderable: false,
					searchable: false,
					createdCell: this.createdCellMarcarEntrada,
				},
				{
					data: 'hora_salida',
					orderable: false,
					searchable: false,
					createdCell: this.createdCellMarcarSalida,
				},
				{
					data: 'action',
					orderable: false,
					searchable: false,
					createdCell: this.createdCellAction,
				},
				{
					data: 'actionviewimg',
					title: 'Foto',
					orderable: false,
					searchable: false,
					createdCell: this.createdCellActionView,
				}
			],
		}
	},
	created() {
		this.$store.dispatch('fechavisitas/addTypeVisita', this.typevisita);
		this.getSelects();
	},
	computed: {
		jsonselects() {
			return this.form.itemsjson
		},
	},
	watch: {
		tipovisitas() {
			$(function () {
				setTimeout(function () {
					$('select').selectpicker('refresh');
				}, 300);
			})
		}
	},
	methods: {
		async getSelects() {
			try {
				const response = await axios.get(route('visitas.selects'));
				this.tipovisitas = response.data.tipovisitas;
			} catch (error) {
				console.error(error);
			}
		},
		jsonform(event) {
			let nameinput = $(event.currentTarget).attr('data-name');
			this.form.itemsjson[nameinput] = {
				id: $(event.currentTarget).find(':selected').attr('data-id'),
				name: $(event.currentTarget).find(':selected').attr('data-name'),
			}
			//this.$store.dispatch('fechavisitas/addTypeVisita', this.typevisita, this.jsonselects.tipovisita.name);
		},
		createdCellMarcarSalida(cell, cellData, rowData) {
			if (cellData == 0) {
				$(cell).empty();
				let actions = Vue.extend(require('@/components/datatables/actionMarcar.vue').default);
				let instance = new actions({
					propsData: {
						id: rowData.id,
						url_marcar: route('visitas.marcar'),
						type: 'salida'
					},
				});
				instance.$mount();
				$(cell).empty().append(instance.$el);
			}
		},
		createdCellMarcarEntrada(cell, cellData, rowData) {
			if (cellData == 0) {
				$(cell).empty();
				let actions = Vue.extend(require('@/components/datatables/actionMarcar.vue').default);

				let instance = new actions({
					propsData: {
						id: rowData.id,
						url_marcar: route('visitas.marcar'),
						type: 'entrada'
					},
				});
				instance.$mount();

				$(cell).empty().append(instance.$el);
			}


		},
		createdCellAction(cell, cellData, rowData) {
			$(cell).empty();
			let actions = Vue.extend(require('@/components/datatables/actionVisitas.vue').default);

			let instance = new actions({
				propsData: {
					id: rowData.id,
					url_eliminar: route('visitas.delete'),
				},
			});
			instance.$mount();
			let me = this;
			instance.$on('edit_emit', function (event) {
				me.$router.push(`visitaprogramada/edit/${rowData.id}`) // -> /user
			})
			instance.$on('view', function (event) {
				me.$router.push(`visitaprogramada/view/${rowData.id}`) // -> /user
			})
			$(cell).empty().append(instance.$el);
		},
		createdCellActionView(cell, cellData, rowData) {
			if (rowData.actionviewimg !== '') {
				$(cell).empty();
				let actions = Vue.extend(require('@/components/datatables/actionViewImg.vue').default);

				let instance = new actions({
					propsData: {
						id: rowData.id,
					},
				});
				instance.$mount();
				let me = this;
				instance.$on('openmodal', function (event) {
					me.srcmodal = `${base_path_asset}storage/Visitas/${rowData.srcfoto}`;
					me.namemodal = rowData.nombre
					me.componentKey += 1
					me.showModal = true;
				})
				$(cell).empty().append(instance.$el);
			}
		},
		tablesearch(event) {
			$('#table_id').DataTable().draw();
		}
	}
}
</script>
