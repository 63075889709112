<template>
  <div class="container">
    <div class="row">
      <!-- Modal -->
      <button @click="marcar" class="col btn btn-success btn-sm" title="Editar">
        <slot>MARCAR</slot>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'url_marcar', 'type'],
  methods: {
    marcar(event) {
      //this.$parent.$router.push(this.url_edit)
      let tipo = false;
      if (this.type == "entrada") {
        tipo = true;
      }
      let me = this;
      swal.fire({
        title: 'Estas seguro?',
        text: tipo ? "Marcar la entrada" : "Marcar la salida",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Marcar!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(this.url_marcar, {
            id_data: this.id,
            type: this.type
          }).then((response) => {
            $('#table_id').DataTable().ajax.reload();

          }).catch((error) => {
            console.error(error);
          });
        }
      })
    },

  },
}
</script>