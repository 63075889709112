<template>
	<div>
		<!-- <header-title>Inicio</header-title> -->
		<section-content>
		    <div class="row">
		    	<div class="col-12">
			     	<div class="card">
			     	   <div class="card-header bg bg-primary">
			     	   		<b>BIENVENIDO AL SISTEMA DE REGISTRO DE VISITAS</b>
			     	   </div>
		              <div class="card-body">

		                <p class="card-text">
                            A través de este sistema podrás registrar visitas, programar visitas, registrar personal que hará uso del sistema.
		                </p>

		              </div>
		            </div>

		     	</div>
		    </div>
		</section-content>

	</div>
</template>
<script>
	import SectionContent from "@/components/SectionContent.vue"
	import HeaderTitle from "@/components/HeaderTitle.vue"
	export default {
		components: {
	      SectionContent,HeaderTitle
	    }
	}
</script>
