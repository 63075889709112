<template>
	<div>
		<!-- Modal -->
		<modal :showModal="showModal">
			<template v-slot:header>
				{{tipemessage}}
			</template>
			<errors-form :errors="validationForm" />
			<success-message :message="message_success" />
			<form class="row g-3" action="" @submit.prevent="formsave" autocomplete="off">
				<div class="col-md-5">
					<label for="" class="text-uppercase">RUC</label>
					<div class="input-group">
						<input v-if="errors && errors.identificacion" type="number" ref="identificacion"
							placeholder="Ingrese RUC" class="form-control rounded-sm is-invalid" id="inputError"
							v-model="form.identificacion">
						<input v-else type="number" ref="identificacion" placeholder="Ingrese RUC"
							class="form-control rounded-sm" v-model="form.identificacion">
						<span class="input-group-append">
							<a v-if="btnBuscarSunat" href="#" class="btn btn-info btn-flat">
								<span class="spinner-border spinner-border-sm"></span>
								<span class="visually-hidden">Buscando..</span></a>
							<a v-else href="#" @click.prevent="frmApiSunat" class="btn btn-info btn-flat"><i
									class="bi bi-search"></i>&nbsp;&nbsp;&nbsp;Buscar&nbsp;</a>
						</span>
					</div>
					<ul>
						<li class="text-danger" v-if="errors && errors.identificacion"><small>{{
						errors.identificacion[0] }}</small></li>
					</ul>
				</div>
				<div class="col-md-7">
					<label for="">Razón social</label>
					<input type="text" v-model="form.nsocial" class="form-control rounded-lg" disabled="">
					<ul>
						<li class="text-danger" v-if="errors && errors.nsocial"><small>{{
						errors.nsocial[0] }}</small></li>
					</ul>
				</div>
				<hr>
				<div class="col-md-6">
					<label for="">Nombres</label>
					<input type="text" class="form-control rounded-lg" v-model="form.nombres"
						@input="$emit('nombres', $event.target.value)" data-toggle="tooltip" data-placement="bottom"
						title="Tooltip on bottom" disabled="">
					<ul>
						<li class="text-danger" v-if="errors && errors.nombres"><small>{{
						errors.nombres[0] }}</small></li>
					</ul>
				</div>
				<div class="col-md-6">
					<label for="">Apellidos</label>
					<input type="text" v-model="form.apellidos" class="form-control rounded-lg" disabled="">
					<ul>
						<li class="text-danger" v-if="errors && errors.apellidos"><small>{{
						errors.apellidos[0] }}</small></li>
					</ul>
				</div>
				<hr>
				<div class="col-md-12">
					<label for="">Dirección</label>
					<input type="text" v-model="form.direccion" class="form-control rounded-lg" disabled="">
					<ul>
						<li class="text-danger" v-if="errors && errors.direccion"><small>{{
						errors.direccion[0] }}</small></li>
					</ul>
				</div>
				<hr>
				<div class="col-md-12 text-center p-3">
					<button class="btn btn-primary"><i class="bi bi-save2-fill"></i>
						Guardar
					</button>
				</div>
			</form>
		</modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import ErrorsForm from "@/components/ValidationErrors.vue"
import successMessage from '@/components/SuccessMessage';
export default {
	props: ['tipe', 'urlform', 'tipemessage', 'validation', 'showModal', 'form'],

	components: {
		Modal, successMessage, ErrorsForm
	},

	data: function () {
		return {
			errors: {},
			inputs: [],
			entidad: {
				nombres: '',
				apellidos: '',
				nsocial: '',
				identificacion: '',
				direccion: '',
			},
			btnBuscarSunat: false,
			validationForm: [],
			message_success: '',
			fullPage: false,
		}
	},
	mounted() {
		this.$refs.identificacion.focus();
	},
	methods: {
		clearform() {
			let me = this;
			Object.keys(this.form).forEach(function (key) {
				if (Array.isArray(me.form[key])) {
					me.form[key] = []
				} else {
					me.form[key] = '';
				}
			})
		},
		loader() {
			return this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				onCancel: this.onCancel,
			});
			
		},
		resetmessage() {
			let me = this;
			setTimeout(function () { me.message_success = ''; me.validationForm = []; }, 7000);
		},
		frmApiSunat() {
			this.btnBuscarSunat = true;
			const params = {
				identificacion: this.form.identificacion,
			};
			this.form.nsocial = '';
			this.form.direccion = '';
			this.form.nombres = '';
			this.form.apellidos = '';
			axios.post(route('sunat'), params).then((response) => {
				const sunat = response.data;
				this.form.nsocial = sunat.nsocial;
				this.form.identificacion = sunat.identificacion;
				this.form.nombres = sunat.nombres;
				this.form.apellidos = sunat.apellidos;
				this.form.direccion = sunat.direccion;
				this.btnBuscarSunat = false;
				this.errors = '';
			}).catch(error => {
				if (error.response.status === 422) {
					this.errors = error.response.data.errors;
					this.btnBuscarSunat = false;
					this.$refs.identificacion.focus();
				} else if (error.response.status === 500) {
					console.log("Error Controlado");
				} else {
					console.log("Error de API");
				}
			});
		},
		formsave() {
			let loader = this.loader();
			let me = this;
			this.message_success = ''

			axios.post(this.urlform, this.form).then((response) => {
				me.validationForm = ''
				if (me.tipe == 'create') {
					me.clearform()
				}
				me.message_success = response.data.success
				$('#table_id').DataTable().ajax.reload();
				loader.hide()
				me.resetmessage();

			}).catch((error) => {
				loader.hide()
				me.validationForm = ''
				if (error.response.status == 422) {
					this.errors = error.response.data.errors;
				} else if (error.response.status == 500) {
					alert(error.response.data.message)
				}
			})
		},

	}
}

</script>