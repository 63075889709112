<template>
  <div class="row g-3">
    <button v-if="$can('Administrator') || $can('Guardia')" @click="edit" class="col btn btn-primary btn-sm"
      title="Editar">
      <i class="bi bi-pencil-square"></i>
    </button>
    <button v-if="$can('admin')" @click="delete_action" class="col btn btn-danger btn-sm" title="Eliminar">
      <i class="bi bi-trash-fill"></i>
    </button>
    <button @click="viewV" class="col btn btn-outline-info btn-sm" title="Visualizar">
      <i class="bi bi-eye-fill"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: ['id', 'url_eliminar'],
  methods: {
    edit(event) {
      this.$emit("edit_emit");
    },
    viewV(event) {
      this.$emit('view')
    },
    delete_action(event) {
      //this.$parent.$router.push(this.url_edit)

      let me = this;
      swal.fire({
        title: 'Estas seguro?',
        text: "Vas a eliminar esta visita",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(this.url_eliminar, {
            id_data: this.id,
          }).then((response) => {
            $(`#codenv${me.id}`).remove();

            $('#table_id').DataTable().ajax.reload();

          }).catch((error) => {
            console.error(error);
          });
        }
      })
    },
    view(event) {

    }

  },
}
</script>