import Vue from 'vue';
import Vuex from 'vuex';
import fechavisitas from './modules/fechavisitas';
import userinit from './modules/UserInit';

Vue.use(Vuex);
Vue.prototype.$apiStatus= 1;

export default new Vuex.Store({

    modules: {
        fechavisitas,
        userinit    
    }
});