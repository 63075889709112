<template>
	<div>
		<section-content>
			<!-- Main row -->
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header bg bg-primary">
							<b><i class="bi bi-person-lines-fill"></i> Empleados </b>
						</div>
						<div class="card-body">
							<router-link to="/empleados/create" class="btn btn-primary">
								<i class="bi bi-plus-circle-fill"></i>
								<b> Registrar empleados</b>
							</router-link>
							<hr>
							<div class="table-responsive">
								<data-table :columns="columns" class="table" :urltable="url_table"></data-table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /.row (main row) -->
		</section-content>
	</div>
</template>
<script>
import SectionContent from "@/components/SectionContent.vue"
import HeaderTitle from "@/components/HeaderTitle.vue"
import DataTable from '@/components/datatables/DataTable.vue';

export default {
	components: {
		SectionContent, HeaderTitle, DataTable

	},
	created() {

	},
	data: function () {
		return {
			url_table: route('empleados.index'),
			urlform: null,
			tipeform: null,
			tipemessage: null,
			showModal: false,
			componentKey: 0,
			form: {},
			columns: [
				{
					data: 'id',
				},
				{
					data: 'dni',
				},
				{
					data: 'empleado',
				},
				{
					data: 'oficina',
				},
				{
					data: 'cargo',
				},
				{
					data: 'created_at',
					title: 'Fecha de creación'
				},
				{
					data: 'action',
					title: 'Acciones',
					orderable: false,
					searchable: false,
					createdCell: this.createdCell,
				}
			],
		}
	},

	methods: {
		loader() {
			return this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				onCancel: this.onCancel,
			});
		},
		
		createform() {
			this.form = {
				dni: '',
				empleado: '',
				cargo:'',
				oficina: ''
			}
			this.urlform = route('users.save')
			this.tipeform = 'create'
			this.tipemessage = `Crear Usuario `
			this.componentKey += 1
			this.showModal = true
		},
		createdCell(cell, cellData, rowData) {
			$(cell).empty()
			let actions = Vue.extend(require('@/components/datatables/actions.vue').default);
			let instance = new actions({
				propsData: {
					id: rowData.id,
					url_delete: route('empleados.delete'),
				},
			});
			instance.$mount()
			let me = this
			instance.$on('edit_emit', function (event) {
				me.$router.push(`empleados/edit/${rowData.id}`) // -> /user
			})
			$(cell).empty().append(instance.$el)
		},
	},
	computed: {
		jsonselects() {
			return this.form.itemsjson
		},
	},
}
</script>
