<template>
	<div>
		<!-- Modal -->
		<modal :showModal="showModal">
			<template v-slot:header>
				{{ tipemessage }}
			</template>
			<errors-form :errors="validationForm" />
			<success-message :message="message_success" />
			<form @submit.prevent="formsave">
				<div class="row">
					<div class="col-md-5">
						<label for="" class="text-uppercase">DNI</label>
						<div class="input-group">
							<input type="number" ref="dni" placeholder="Identificacion" class="form-control rounded-sm"
								v-model="form.dni">
							<span class="input-group-append">
								<a v-if="btnBuscarReniec" href="#" class="btn">
									<span class="spinner-border spinner-border-sm"></span>
									<span class="visually-hidden">Buscando..</span></a>
								<a v-else href="#" @click.prevent="frmApiReniec" class="btn btn-info btn-flat">
									<i class="bi bi-search"></i>&nbsp;&nbsp;&nbsp;Buscar&nbsp;</a>
							</span>
						</div>
						<ul v-if="errors && errors.dni">
							<li class="text-danger" v-if="errors && errors.dni"><small>{{ errors.dni[0] }}</small></li>
						</ul>
					</div>
					<div class="col-md-7">
						<label for="">Nombre:</label>
						<input type="text" ref="name" id="name" v-model="form.name" class="form-control rounded-lg" placeholder=""
							disabled="disabled">
						<ul v-if="errors && errors.name">
							<li class="text-danger" v-if="errors && errors.name"><small>{{ errors.name[0] }}</small>
							</li>
						</ul>
					</div>
				</div>
				<div class="row mt-md-2">
					<div class="col-md-5">
						<label for="">Email:</label>
						<input type="text" ref="email" v-model="form.email" class="form-control rounded-lg"
							placeholder="example: example@gmail.com" style="text-transform: lowercase;">
						<ul v-if="errors && errors.email">
							<li class="text-danger" v-if="errors && errors.email"><small>{{ errors.email[0] }}</small>
							</li>
						</ul>
					</div>
					<div class="col-md-4">
						<label for="">Password:</label>
						<input type="password" ref="password" v-model="form.password" class="form-control rounded-lg"
							placeholder="Ingresa la clave">
						<ul v-if="errors && errors.password">
							<li class="text-danger" v-if="errors && errors.password"><small>{{ errors.password[0]
									}}</small>
							</li>
						</ul>
					</div>
					<div class="col-md-3">
						<label for="">Rol:</label>
						<select class="form-control rounded-lg" ref="rol" v-model="form.rol">
							<option value="">Seleccionar rol..</option>
							<option v-for="item in rolesall" :key="item.id">
								{{ item.name }}
							</option>
						</select>
						<ul v-if="errors && errors.rol">
							<li class="text-danger" v-if="errors && errors.rol"><small>{{ errors.rol[0] }}</small>
							</li>
						</ul>
					</div>
				</div>
				<hr>
				<div class="row mt-2">
					<div class="col-12 text-center">
						<button class="btn btn-primary"><i class="bi bi-save2-fill"></i> Guardar</button>
					</div>
				</div>
			</form>
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ErrorsForm from "@/components/ValidationErrors.vue"
import successMessage from '@/components/SuccessMessage';
import { switchCase } from '@babel/types';
export default {
	props: ['form', 'tipe', 'urlform', 'tipemessage', 'validation', 'showModal'],

	components: {
		Modal, successMessage, ErrorsForm
	},
	created() {
		this.init();
	},
	mounted() {
		this.$refs.dni.focus();
	},
	data: function () {
		return {
			errors: {},
			validationForm: [],
			message_success: '',
			fullPage: false,
			rolesall: [],
			btnBuscarReniec: false,
			errors: {},
			name: '',
		}
	},
	methods: {
		async init() {
			try {
				const response = await axios.get(route('users.rolesall'));
				this.rolesall = response.data;
			} catch (error) {
				console.error(error);
			}
		},
		clearform() {
			let me = this;
			Object.keys(this.form).forEach(function (key) {
				if (Array.isArray(me.form[key])) {
					me.form[key] = []
				} else {
					me.form[key] = '';
				}
			})
		},
		loader() {
			return this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				onCancel: this.onCancel,
			});
		},
		resetmessage() {
			let me = this;
			setTimeout(function () { me.message_success = ''; me.validationForm = []; }, 7000);
		},
		frmApiReniec() {
			this.btnBuscarReniec = true;
			this.form.name = '';
			const params = {
				dni: this.form.dni,
			};
			axios.post(route('reniec'), params).then(response => {
				if (response.data.nombres == '') {
					swal.fire({
						title: 'Consulta Reniec',
						text: "No se encontraron resultados",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("empleado").disabled = false;
							this.$refs.empleado.focus();
						}
					});
					
					return;
				}
				const reniec = response.data;
				this.form.name = reniec.nombres;
				this.btnBuscarReniec = false;
				this.errors = {};
			}).catch(error => {
					if (error.response.status === 422) {
						this.errors = error.response.data;
						this.$refs.dni.focus();
						this.btnBuscarReniec = false;
					} else if (error.response.status == 500) {

						swal.fire({
							title: 'Error con la conexión a RENIEC',
							text: "¡Por favor!, llenar los datos manualmente.",
							icon: 'error',
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							didClose: (e) => {
								this.btnBuscarReniec = false;
								document.getElementById("name").disabled = false;
								this.$refs.name.focus();
							},
							confirmButtonText: 'Aceptar!',
						}).then((result) => {
							if (result.isConfirmed) {
								this.btnBuscarReniec = false;
								document.getElementById("name").disabled = false;
							}
						});
					} else {

						swal.fire({
							title: 'Error con la conexión a RENIEC',
							text: "¡Por favor!, llenar los datos manualmente.",
							icon: 'error',
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							didClose: (e) => {
								this.btnBuscarReniec = false;
								document.getElementById("name").disabled = false;
								this.$refs.name.focus();
							},
							confirmButtonText: 'Aceptar!',
						}).then((result) => {
							if (result.isConfirmed) {
								this.btnBuscarReniec = false;
								document.getElementById("name").disabled = false;
							}
						});
					}				
			}).finally(() => this.loading = false);
		},
		formsave() {
			let loader = this.loader();
			let me = this;
			this.message_success = ''
			axios.post(this.urlform, this.form).then((response) => {
				me.validationForm = ''
				if (me.tipe == 'create') {
					me.clearform()
				}
				me.message_success = response.data.success
				$('#table_id').DataTable().ajax.reload();
				loader.hide()
				me.resetmessage();
			}).catch((error) => {
				loader.hide()
				me.validationForm = ''
				if (error.response.status == 422) {
					this.errors = error.response.data.errors;
					for (var key in this.errors) {
						switch (key) {
							case 'email':
								this.$refs.email.focus();
								break;
							case 'password':
								this.$refs.password.focus();
								break;
							case 'rol':
								this.$refs.rol.focus();
								break;
							default:
								this.$refs.dni.focus();
						}
						if (key) {
							break;
						}
						console.log(key);
					}
				} else if (error.response.status == 500) {
					alert(error.response.data.message)
				}
			})
		}
	}
};
</script>