<template>
  <div class="user-panel mt-3 pb-1 mb-1 d-flex align-items-center elevation-1 rounded-lg">
    <div class="image">
      <img :src="userimg" class="img-circle elevation-2" alt="img">
    </div>
    <div class="info">
      <a href="#" class="d-block fw-bold">{{ user.name }}</a>
      <div class="info bg-light" style="position: absolute; right: 0px; top: 0px;">
        <a href="#" data-toggle="modal" data-target="#editProfileModal" class="d-block">
          <i class="bi bi-pencil-square"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['asset'],
  data: function () {
    return {
      url_img: null
    };
  },
  created() {
    this.$store.dispatch('usersession');
  },
  computed: {
    user() {
      let user = this.$store.getters.getUser.name;
      return this.$store.getters.getUser
    },
    userimg() {
      let user = this.$store.getters.getUser;
      return user.img_src !== null ? `${this.asset}storage/profile/${user.id}/${user.img_src}` : `${this.asset}/img/avatar5.png`
    }
  },
}

</script>