<template>
	<div>
		<section-content>
			<!-- Main row -->
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header bg bg-info">
							<div class="row">
								<div class="col-6">
									<b><i class="bi bi-eye-fill"></i> Agregar Visita</b>
								</div>
								<div class="col-6 text-right">
									<a @click="$router.go(-1)" href="#">
										<b class="text-white"><i class="bi bi-reply-fill"></i> Atras..</b>
									</a>
								</div>
							</div>
						</div>
						<div class="card-body" id="bodycontent">
							<errors-form :errors="validationForm" />
							<success-message :message="message_success" />

							<form @submit.prevent="saveform" id="formContainer">
								<!--Inputs selects y otros en un componente llamado head-form-->
								<button type="submit" disabled hidden aria-hidden="true"></button>
								<head-form :form="form" type="create" @nombre="form.nombre = $event"
									@dni="form.dni = $event" @nsocial="form.nsocial = $event"
									@fechaprogramada="form.fecha_programada = $event"
									@itemjson="form.itemsjson = $event" />									
								<foto-modal :form="form" />
								<input-dynamic :form="form" :inputs="inputs" @inputarray="inputs = $event"
									@herramientastatus="form.herramientastatus = $event" />
								<!--Componente para tomar la foto mediante un modal -->
								<div class="row mt-2">
									<div class="col-12 text-center">
										<button class="btn btn-primary"><i class="bi bi-save2-fill"></i>
											Guardar</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- /.row (main row) -->
		</section-content>
	</div>
</template>
<script>
import SectionContent from "@/components/SectionContent.vue"
import HeaderTitle from "@/components/HeaderTitle.vue"
import HeadForm from "@/components/visitas/head.vue"
import FotoModal from "@/components/visitas/FotoModal.vue"
import InputDynamic from "@/components/visitas/InputDynamic.vue"
import ErrorsForm from "@/components/ValidationErrors.vue"
import successMessage from '@/components/SuccessMessage.vue';
import route from "../../../../vendor/tightenco/ziggy/src/js";

let user = document.head.querySelector('meta[name="usuario"]');

export default {
	components: {
		SectionContent, HeaderTitle, HeadForm, FotoModal, successMessage, ErrorsForm, InputDynamic
	},
	data: function () {
		return {
			form: {
				nombre: '',
				dni: '',
				herramientastatus: '',
				srcfoto: null,
				fecha_programada: moment().format('YYYY/MM/DD'),

				itemsjson: {
					entidad: { id: 0, name: '-' },
					empleado: { id: 0, name: '-' },
					sede: { id: 1, name: 'HOSPITAL GENERAL JAÉN - PRINCIPAL' },
					oficina: { id: 0, name: '-' },
					motivo: { id: 0, name: '-' },
					usuario: { id: 0, name: '-' },
					tipovisita: { id: 0, name: '-' },
					tipo: { id: 1, name: 'DNI' },
				},
			},
			inputs: [],
			validationForm: [],
			message_success: '',
			fullPage: false
		}
	},

	methods: {
		loader(elementid) {
			return this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : document.getElementById(elementid),
				canCancel: false,
				onCancel: this.onCancel,
			});
		},
		resetmessage() {
			let me = this;
			setTimeout(function () { me.message_success = ''; me.validationForm = []; }, 7000);
		},
		clearform() {
			let me = this;
			Object.keys(this.form).forEach(function (key) {
				if (Array.isArray(me.form[key])) {
					me.form[key] = []
				} else {
					me.form[key] = null;
				}
			})
		},
		saveform() {
			let loader = this.loader('formContainer');
			let me = this;
			this.message_success = '';
/* 			this.form.itemsjson.usuario.id = JSON.parse(user.content).id;
			this.form.itemsjson.usuario.name = JSON.parse(user.content).name; */
			this.form.inputs = this.inputs;
			axios.post(route('visitas.create'), this.form).then(function (response) {
				me.validationForm = ''
				me.message_success = response.data.success;
				setTimeout(function () {
					me.message_success = response.data.success;
					history.back();
				}, 2000);
			})
				.catch(function (error) {
					loader.hide();
					me.validationForm = '';
					if (error.response.status == 422) {
						me.validationForm = error.response.data;
					} else if (error.response.status == 500) {
						alert(error.response.data.message)
					}
				});
		},
	},
	mounted() {

	}
}
</script>