<template>
    <div>

        <div class="row">
            <div class="col-md-5">
                <label for="" class="text-uppercase">DNI</label>
                <div class="input-group">
                    <input type="number" ref="dni" placeholder="Identificacion" class="form-control rounded-sm"
                        :value="form.dni" @input="$emit('dni', $event.target.value)">
                    <span class="input-group-append">
                        <a v-if="btnBuscarReniec" href="#" class="btn">
                            <span class="spinner-border spinner-border-sm"></span>
                            <span class="visually-hidden">Buscando..</span></a>

                        <a v-else href="#" @click.prevent="frmApiReniec" class="btn btn-info btn-flat">
                            <i class="bi bi-search"></i>&nbsp;Buscar&nbsp;</a>
                    </span>
                </div>
                <ul>
                    <li class="text-danger" v-if="errors && errors.dni"><small>{{ errors.dni[0] }}</small></li>
                </ul>
            </div>
            <div class="col-md-7">
                <label for="" class="text-uppercase">Nombre y Apellido</label>
                <input type="text" placeholder="" ref="empleado" id="empleado" class="form-control rounded-lg" :value="form.empleado"
                    @input="$emit('empleado', $event.target.value)" disabled="disabled">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label for="" class="text-uppercase">OFICINA</label>
                <select name="oficina" id="" class="form-control jsoninput" data-name="oficina" data-live-search="true"
                    @change="jsonform">
                    <option value="">SELECCIONAR OFICINA..</option>
                    <option v-for="item in oficinas" :key="item.id" :data-name="item.oficina" :data-id="item.id"
                        :selected="item.id == jsonselects.oficina.id">
                        {{ item.oficina }}
                    </option>
                </select>
            </div>
            <div class="col-md-6">
                <label for="" class="text-uppercase">CARGO</label>
                <select name="cargo" id="" class="form-control jsoninput" data-name="cargo" data-live-search="true"
                    @change="jsonform">
                    <option value="">SELECCIONAR CARGO..</option>
                    <option v-for="item in cargos" :key="item.id" :data-name="item.cargo" :data-id="item.id"
                        :selected="item.id == jsonselects.cargo.id">
                        {{ item.cargo }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: ['form', 'type'],

    data: function () {
        return {
            errors: {},
            id: null,
            loading: '',
            oficinas: null,
            cargos: null,

            btnBuscarReniec: false,
        }
    },
    mounted() {
        this.getSelects();
        this.$refs.dni.focus();
    },
    methods: {
        async getSelects() {
            try {
                const response = await axios.get(route('empleados.selects'));
                this.oficinas = response.data.oficinas;
                this.cargos = response.data.cargos;
            } catch (error) {
                console.error(error);
            }
        },
        jsonform(event) {
            let nameinput = $(event.currentTarget).attr('data-name');
            this.form.itemsjson[nameinput] = {
                id: $(event.currentTarget).find(':selected').attr('data-id'),
                name: $(event.currentTarget).find(':selected').attr('data-name'),
            }
            // this.jsonselects.entidad.id = 1
            //alert(this.jsonselects.entidad.id + this.jsonselects.entidad.name);
        },
        frmApiReniec() {
			this.btnBuscarReniec = true;
			this.form.empleado = '';
			// const apiReniec = "http://172.16.100.18:80/api/consulta-reniec";
			//const apiReniec = "/reniec";
			const params = {
				dni: this.form.dni,
			};
			axios.post(route('reniec'), params).then(response => {
				if (response.data.nombres == '') {
					swal.fire({
						title: 'Consulta Reniec',
						text: "No se encontraron resultados",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("empleado").disabled = false;
							this.$refs.empleado.focus();
						}
					});
					
					return;
				}

				const reniec = response.data;
				this.form.empleado = reniec.nombres;
				this.btnBuscarReniec = false;
				this.errors = {};
				this.selectDatosEr();
			}).catch(error => {
				if (error.response.status === 422) {
					this.errors = error.response.data.errors;
					this.$refs.dni.focus();
					this.btnBuscarReniec = false;
				} else if (error.response.status == 500) {
					swal.fire({
						title: 'Error con la conexión a RENIEC',
						text: "¡Por favor!, llenar los datos manualmente.",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("empleado").disabled = false;
							this.$refs.empleado.focus();
						},
						confirmButtonText: 'Aceptar!',
					}).then((result) => {
						if (result.isConfirmed) {
							this.btnBuscarReniec = false;
							document.getElementById("empleado").disabled = false;
						}
					});
				} else {
					swal.fire({
						title: 'Error con la conexión a RENIEC',
						text: "¡Por favor!, llenar los datos manualmente.",
						icon: 'error',
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						didClose: (e) => {
							this.btnBuscarReniec = false;
							document.getElementById("empleado").disabled = false;
							this.$refs.empleado.focus();
						},
						confirmButtonText: 'Aceptar!',
					}).then((result) => {
						if (result.isConfirmed) {
							this.btnBuscarReniec = false;
							document.getElementById("empleado").disabled = false;
						}
					});
				}
			}).finally(() => this.loading = false);

		},
    },
    computed: {
        jsonselects() {
            return this.form.itemsjson
        },
    },
    watch: {
        oficinas() {
            $(function () {
                setTimeout(function () {
                    $('select').selectpicker('refresh');
                }, 300);
            })
        }
    },
}
</script>