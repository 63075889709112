<template>
	<div>
		<section-content>
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header bg bg-info">
							<div class="row">
								<div class="col-6">
									<b><i class="bi bi-eye-fill"></i>&nbsp;&nbsp; Ver Visita</b>
								</div>
								<div class="col-6 text-right text-white">
									<a @click="$router.go(-1)" href="#">
										<b class="text-white"><i class="bi bi-reply-fill"></i> Atras..</b>
									</a>
								</div>
							</div>
						</div>
						<div class="card-body">
							<form action="">
								<div class="row">
									<div class="col-md-4">
										<label for="">NOMBRE Y APELLIDO</label>
										<input type="text" readonly="" class="form-control" v-model="form.nombre">
									</div>
									<div class="col-md-4">
										<label for="">DNI</label>
										<input type="text" readonly="" class="form-control" v-model="form.dni">
									</div>
									<div class="col-md-4">
										<label for="">ENTIDAD</label>
										<input type="text" readonly="" class="form-control"
											v-model="jsonselects.entidad.name">
									</div>
								</div>
								<hr>
								<div class="row">
									<div class="col-md-4">
										<label for="">MOTIVO</label>
										<input type="text" readonly="" class="form-control"
											v-model="jsonselects.motivo.name">
									</div>
									<div class="col-md-4">
										<label for="">EMPLEADO</label>
										<input type="text" readonly="" class="form-control"
											v-model="jsonselects.empleado.name">

									</div>
									<div class="col-md-4">
										<label for="">OFICINA</label>
										<input type="text" readonly="" class="form-control"
											v-model="jsonselects.empleado.emp">

									</div>
								</div>
								<hr>
								<div class="row">
									<div class="col-md-4">
										<label for="">CONTACTO</label>
										<input type="text" readonly="" class="form-control" v-model="form.lugar">

									</div>
									<div class="col-md-4">
										<label for="">LUGAR</label>
										<input type="text" readonly="" class="form-control"
											v-model="jsonselects.oficina.name">
									</div>
									<div class="col-md-4">
										<label for="">SEDE</label>
										<input type="text" readonly="" class="form-control"
											v-model="jsonselects.sede.name">
									</div>
								</div>
								<div class="row justify-content-center m-2" v-if="form.srcfoto !== null">
									<div class="col-3">
										<div class="alert alert-info">
											<b><i class="bi bi-camera-fill"></i> 1 imagen tomada</b>
											<a href="#" @click.prevent="showimgsave"> <b>Ver imagen guardada</b></a>
										</div>
									</div>
								</div>
								<modal :showModal="showModal" :key="componentKey">
									<template v-slot:header>
										<b>Imagen de la visita</b>
									</template>
									<img :src="`${srcmodal}`" alt="" class="img-fluid w-100" />
								</modal>
								<!--Inputs Guardados -->
								<div class="row mt-5" v-for="(item,index) in form.herramientas" :key="item.id">
									<div class="col-md-4 text-center">
										<label for="" class="text-uppercase ">Herramienta de trabajo</label>
										<input type="text" v-model="item.nombre" class="form-control" readonly="">
									</div>
									<div class="col-md-4  text-center">
										<label for="" class="text-uppercase ">Marca</label>

										<input type="text" v-model="item.marca" class="form-control" readonly="">
									</div>
									<div class="col-md-4 text-center">
										<label for="" class="text-uppercase ">Serial</label>

										<input type="text" v-model="item.serial" class="form-control" readonly="">
									</div>

								</div>
								<!--////-->
							</form>
						</div>
					</div>
				</div>
			</div>
		</section-content>
	</div>
</template>
<script>
import SectionContent from "@/components/SectionContent.vue"
import HeaderTitle from "@/components/HeaderTitle.vue"
import Modal from '@/components/Modal.vue'

export default {
	components: {
		SectionContent, HeaderTitle, Modal
	},
	data: function () {
		return {
			showModal: false,
			componentKey: 0,
			jsoninputs: {
				entidad: { id: 0, name: '-' },
				empleado: { id: 0, name: '-' },
				sede: { id: 0, name: '-' },
				oficinas: { id: 0, name: '-', emp: '' },
				motivo: { id: 0, name: '-' },
			},
			form: {
				nombre: '',
				dni: '',
				lugar: '',
				herramientastatus: '',
				srcfoto: null,
				itemsjson: null,
			},
		}
	},
	created() {
		this.init();
	},
	computed: {
		jsonselects() {
			return (this.form.itemsjson !== null) ? this.form.itemsjson : this.jsoninputs
		},
		srcmodal() {
			return `${base_path_asset}storage/Visitas/${this.form.srcfoto}`
		}
	},
	methods: {
		async init() {
			try {
				const response = await axios.get(route('visitas.edit', { id: this.$route.params.id }));
				if (response.data === '') {
					this.$router.push({ path: '/visitas' })
				}
				this.form = response.data
			} catch (error) {
				console.error(error);
			}
		},
		showimgsave() {
			this.showModal = true
			this.componentKey += 1
		}
	}
}
</script>