<template>
	<div>
		<section-content>
			<!-- Main row -->
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header bg bg-primary">
							<div class="row">
								<div class="col-6">
									<b><i class="bi bi-eye-fill"></i>&nbsp;&nbsp;Formulario Edicion de Visitas</b>
								</div>
								<div class="col-6 text-right text-white">
									<a @click="$router.go(-1)" href="#">
										<b class="text-white"><i class="bi bi-reply-fill"></i> Atrás...</b>
									</a>
								</div>
							</div>
						</div>
						<div class="card-body">
							<errors-form :errors="validationForm" />
							<success-message :message="message_success" />

							<form @submit.prevent="saveform" id="formContainer">
								<!--Inputs selects y otros en un componente llamado head-form-->
								<head-form :form="form" type="edit" @nombre="form.nombre = $event"
									@dni="form.dni = $event" @itemjson="form.itemsjson = $event"
									@lugar="form.lugar = $event" />

								<input-dynamic :form="form" :inputs="inputs" @inputarray="inputs = $event"
									@herramientastatus="form.herramientastatus = $event" />

								<!--Componente para tomar la foto mediante un modal -->
								<foto-modal editform="true" :form="form" />

								<div class="row mt-3">
									<div class="col-12 text-center">
										<button class="btn btn-primary"><i class="bi bi-save2-fill"></i>
											Guardar</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- /.row (main row) -->
		</section-content>

	</div>
</template>
<script>
import SectionContent from "@/components/SectionContent.vue"
import HeaderTitle from "@/components/HeaderTitle.vue"
import HeadForm from "@/components/visitas/head.vue"
import FotoModal from "@/components/visitas/FotoModal.vue"
import InputDynamic from "@/components/visitas/InputDynamic.vue"
import ErrorsForm from "@/components/ValidationErrors.vue"
import successMessage from '@/components/SuccessMessage.vue';
import Modal from '@/components/Modal.vue';

export default {
	components: {
		SectionContent, HeaderTitle, HeadForm, FotoModal, successMessage, ErrorsForm, Modal, InputDynamic
	},
	created() {
		this.init();
	},
	data: function () {
		return {
			form: {
				nombre: '',
				dni: '',
				lugar: '',
				herramientastatus: '',
				srcfoto: null,
				fecha_programada: null,

				itemsjson: {
					entidad: { id: 0, name: '-' },
					empleado: { id: 0, name: '-' },
					sede: { id: 0, name: '-' },
					oficina: { id: 0, name: '-' },
					motivo: { id: 0, name: '-' },
					tipo: { id: 0, name: '-' },
					//usuario: { id: this.$store.getters.getUser.id, name: this.$store.getters.getUser.name }
				},
			},
			inputs: [],
			errors: {},
			validationForm: [],
			message_success: '',
			fullPage: false
		}
	},
	methods: {
		async init() {
			try {
				const response = await axios.get(route('visitas.edit', { id: this.$route.params.id }));
				if (response.data === '') {
					this.$router.push({ path: '/visitas' })
				}
				if (typeof response.data.itemsjson.tipo === 'object') {
					this.form = response.data;
				} else {
					this.form = response.data;
					/*this.form.id = response.data.id;
					this.form.dni = response.data.dni;
					this.form.nombre = response.data.nombre;
					this.form.itemsjson.entidad = response.data.itemsjson.entidad;
					this.form.itemsjson.motivo = response.data.itemsjson.motivo;
					this.form.itemsjson.empleado = response.data.itemsjson.empleado;
					this.form.itemsjson.sede = response.data.itemsjson.sede;
					this.form.itemsjson.oficina = response.data.itemsjson.oficina;
					this.form.fecha = response.data.fecha;
					this.form.itemsjson.tipo.id = 1;
					this.form.itemsjson.tipo.name = 'DNI' */
				}
				switch (this.form.itemsjson.tipo.id) {
					case '1':
						document.getElementById("nombres").disabled = true;
						this.tipoDocumento = true;
						break;
					case '2':
						document.getElementById("nombres").disabled = false;
						this.tipoDocumento = false;
						break;
					case '3':
						document.getElementById("nombres").disabled = false;
						this.tipoDocumento = false;

						break;
				}


			} catch (error) {
				console.error(error);
			}
		},
		loader(elementid) {
			return this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : document.getElementById(elementid),
				canCancel: false,
				onCancel: this.onCancel,
			});

		},
		resetmessage() {
			let me = this;
			setTimeout(function () { me.message_success = ''; me.validationForm = []; }, 7000);
		},
		clearform() {
			let me = this;
			Object.keys(this.form).forEach(function (key) {
				if (Array.isArray(me.form[key])) {
					me.form[key] = []
				} else {
					me.form[key] = null;
				}
			})
		},

		saveform() {
			let loader = this.loader('formContainer');
			let me = this;
			this.form.inputs = this.inputs

			axios.post(route('visitas.update'), this.form)
				.then(function (response) {
					me.validationForm = ''
					me.message_success = response.data.success;
					setTimeout(function () {
						me.message_success = response.data.success;
						history.back();
					}, 1000);
				})
				.catch(function (error) {
					loader.hide()
					me.validationForm = ''
					if (error.response.status == 422) {
						me.validationForm = error.response.data;
					} else if (error.response.status == 500) {
						alert(error.response.data.message)
					}
				});
		}
	},
	mounted() {

	}
}
</script>
