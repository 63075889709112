<template>
	<div>
		<!-- Main row -->
		<div class="row">
			<div class="col-12">
				<div class="card mt-0">
					<hr class="border border-info border-2 rounded-lg opacity-50 m-0">
					<div class="card-body">
						<DatePicker></DatePicker>
						<hr>
						<div class="table-responsive">
							<data-table :columns="columns" class="table table-bordered" :urltable="url_table">
							</data-table>
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- /.row (main row) -->
	</div>
</template>
<script>
import DataTable from '@/components/datatables/DataTableVisitasP.vue';
import 'moment/locale/es';
import Modal from '@/components/Modal.vue';

import { mapGetters } from 'vuex';
import DatePicker from '../../components/DatePicker.vue';
export default {
	props: ['typevisita'],
	name: "disabled_dates",
	components: {
		DataTable,
		Modal,
		DatePicker
	},
	data: function () {
		return {
			url_table: route('visitas.publico'),
			showModal: false,
			componentKey: 0,
			srcmodal: null,
			namemodal: null,
			columns: [
				{
					data: 'id',
					visible: false,
				},
				{
					data: 'fecha_programada',
					title: 'Fecha&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
				},
				{
					data: 'nombre',
					title: 'Visitante'
				},
				{
					data: 'dni',
					render: function (data, type, row) {
						if (row.tipo) {
							var ddni = `${row.tipo} ${row.dni}`;
						} else {
							var ddni = `${row.dni}`;
						}
						return ddni;
					},
					title: 'Identificacion ',

				},
				{
					data: 'entidad',
					title: 'Entidad&nbsp;del&nbsp;Visitante'
				},
				{
					data: 'motivo',
					title: 'Motivo'
				},
				{
					data: 'empleado',
					title: 'Funcionario&nbsp;visitado'
				},
				{
					data: 'oficina',
					title: 'Lugar Específico'
				},
				{
					data: 'hora_entrada',
					title: 'Hora&nbsp;Entrada'
				},
				{
					data: 'hora_salida',
					title: 'Hora&nbsp;Salida'
				},

			],
		}
	},

	created() {
		this.$store.dispatch('fechavisitas/addTypeVisita', 2)
	},
	computed: {

	},
	methods: {

	}
}
</script>
